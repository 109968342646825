<template>
    <div>
        <b-form-row>
            <!-- name -->
            <b-form-group
                :id="locale + '.group-record.name'"
                :label="$t('form.name')"
                class="col-sm-12 col-md-6"
                description=""
                label-class="mb-0"
                label-for="record.name"
            >
                <b-form-input
                    :id="locale + '.name'"
                    v-model="record.name"
                ></b-form-input>
            </b-form-group>
            <!-- keyword -->
            <b-form-group
                :id="locale + '.type-record.keyword'"
                class="col-sm-12 col-md-6"
                label-class="mb-0"
                :label="$t('form.keyword.label')"
                :label-for="locale + 'keyword'"
                description=""
            >
                <b-form-tags
                    :id="locale + 'keyword'"
                    v-model="record.keyword"
                    :duplicateTagText="$t('form.keyword.duplicate')"
                    :invalidTagText=null
                    :placeholder="null"
                    :tag-validator="validator"
                    addButtonText="Dodaj"
                    remove-on-delete
                    separator=" ,;"
                ></b-form-tags>
            </b-form-group>
        </b-form-row>
        <!-- notes -->
        <b-form-group
            id="group-record.locale.notes"
            :label="$t('form.notes')"
            class="col-sm-12 p-0"
            description=""

            label-class="mb-0"
            label-for="record.locale.notes"
        >
            <vue-editor
                v-model="record.notes"
            ></vue-editor>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: "FormProductTypeLocale",
    components: {},
    props: {
        locale: '',
        record: {},
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        validator(tag) {
            return tag.length > 2
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
